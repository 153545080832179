import React from 'react';
import * as PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { percentFormatter } from '../../../components/helpers/utils';

const useStyles = makeStyles({
  root: {
    marginLeft: 10,
    maxHeight: 120,
    maxWidth: 500,
    backgroundColor: '#212121',
  },
  title: {
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
  typo: {
    textAlign: 'right',
    marginLeft: 10,
    fontSize: 14,
    color: 'white',
    fontWeight: 500,
  },
});

function ConversionAvgMed({ purchaseStore }) {
  const { conversionAvg, conversionMed } = purchaseStore;

  const classes = useStyles();
  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 30 }}
    >
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Średnia konwersja w wybranym zakresie dat:
          </Typography>
          <Typography className={classes.typo} color="textSecondary">
            {percentFormatter.format(conversionAvg)}
          </Typography>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Mediana konwersji w wybranym zakresie dat:
          </Typography>
          <Typography className={classes.typo} color="textSecondary">
            {percentFormatter.format(conversionMed)}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default inject('purchaseStore')(observer(ConversionAvgMed));

ConversionAvgMed.propTypes = {
  purchaseStore: PropTypes.shape({
    conversionAvg: PropTypes.number,
    conversionMed: PropTypes.number,
  }),
};
